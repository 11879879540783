@import "/src/theme.scss";
@import "src/assets/styles/variables.scss";
@import "src/assets/styles/global-style.scss";
@import "src/assets/styles/media.scss";

@font-face {
  font-family: "DavisSans-Bold";
  src: url("#{$font-path}/en/DavisSans-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-BoldItalic";
  src: url("#{$font-path}/en/DavisSans-BoldItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Italic";
  src: url("#{$font-path}/en/DavisSans-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Light";
  src: url("#{$font-path}/en/DavisSans-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-LightItalic";
  src: url("#{$font-path}/en/DavisSans-LightItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Medium";
  src: url("#{$font-path}/en/DavisSans-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-MediumItalic";
  src: url("#{$font-path}/en/DavisSans-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DavisSans-Regular";
  src: url("#{$font-path}/en/DavisSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

//--------Stratos font face---------
@font-face {
  font-family: "Stratos-Bold";
  src: url("#{$font-path}/en/Stratos-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-BoldItalic";
  src: url("#{$font-path}/en/Stratos-BoldItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Italic";
  src: url("#{$font-path}/en/Stratos-Italic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Light";
  src: url("#{$font-path}/en/Stratos-Light.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-LightItalic";
  src: url("#{$font-path}/en/Stratos-LightItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Medium";
  src: url("#{$font-path}/en/Stratos-Medium.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-MediumItalic";
  src: url("#{$font-path}/en/Stratos-MediumItalic.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stratos-Regular";
  src: url("#{$font-path}/en/Stratos-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
//----------------------------------

* {
  font-family: "DavisSans-Regular";
  color: white;
  margin: 0;
  padding: 0;
}

p {
  font-weight: 400;
  line-height: 1.5rem;
}

html {
  overflow: hidden !important;
  background-color: $gray-1;
}

a {
  display: block;
  color: $white-color;
  text-decoration: underline;
}

html,
body {
  height: 100%;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 0;
  margin: 0;

  & * {
    font-family: inherit !important;
    letter-spacing: unset !important;
  }
}

h5 {
  color: $white-color;
  font-size: 1.16rem !important;
}

i {
  display: block;
}

button,
a {
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $light-gray;
  width: 8px;
}

//-----------a HTML Tag styles-----------

// @media (max-width: 420px) {
//   :root {
//     font-size: 70%;
//   }
// }

@import "./assets/styles/modal.scss";
@import "./assets/styles/controls.scss";
