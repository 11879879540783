@mixin d-flex {
  display: flex;
}

@mixin flex-center {
  @include d-flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  @include d-flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  @include d-flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-between {
  @include d-flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around {
  @include d-flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-evenly {
  @include d-flex;
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}

@mixin flex-col {
  flex-direction: column;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin bg-view {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

@mixin bg-auto {
  @include bg-view;
  background-size: auto;
}

@mixin bg-contain {
  @include bg-view;
  background-size: contain;
}

@mixin bg-full {
  @include bg-view;
  background-size: 100%;
}

@mixin bg-cover {
  @include bg-view;
  background-size: cover;
}

@mixin full-size {
  @extend .full-size;
}

@mixin float-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@mixin text-overflow {
  @extend .text-overflow;
}

@mixin cover-center-img {
  object-fit: cover;
  object-position: center;
}

.me-4 {
  margin-inline-end: 4px;
}

.me-8 {
  margin-inline-end: 8px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-8 {
  margin-block-end: 8px;
}

.mb-16 {
  margin-block-end: 16px;
}

.mb-32 {
  margin-block-end: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mx-8 {
  margin-inline: 8px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mt-8 {
  margin-block-start: 8px;
}

.mt-6 {
  margin-block-start: 6px;
}

.mt-4 {
  margin-block-start: 4px;
}

.ph-16 {
  padding-inline: 16px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

.pos-rel {
  position: relative;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.full-width {
  width: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.light-font {
  font-family: "DavisSans-Light";
}

.bold-font {
  font-family: "DavisSans-Bold";
}

.italic-font {
  font-family: "DavisSans-Italic";
}

.regular-font {
  font-family: "DavisSans-Regular";
}

.medium-font {
  font-family: "DavisSans-Medium";
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.ico {
  @include bg-contain;
  display: inline-block;
}

.x10 {
  width: 10px;
  height: 10px;
}

.x16 {
  width: 16px;
  height: 16px;
}

.x18 {
  width: 18px;
  height: 18px;
}

.x24 {
  width: 24px;
  height: 24px;
}

.x28 {
  width: 28px;
  height: 28px;
}

.x30 {
  width: 30px;
  height: 30px;
}

.x32 {
  width: 32px;
  height: 32px;
}

.x36 {
  width: 36px;
  height: 36px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-0 {
  transform: rotate(0deg);
}

.translate-x-0 {
  transform: translateX(0) !important;
}

.overflow-y {
  overflow-y: auto;
  height: 100%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.def-txt-trans {
  text-transform: initial;
}

.cap-txt-trans {
  text-transform: capitalize;
}

.low-txt-trans {
  text-transform: lowercase;
}

.upr-txt-trans {
  text-transform: uppercase;
}

.inh-txt-trans {
  text-transform: inherit;
}

.w-max-content {
  width: max-content;
}

.fs-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.link {
  font-size: 1rem;
  font-weight: 400;
  color: #98a6f9;
}

.mask-link {
  @include float-layer;
  @include full-size;
}

.full-opacity {
  opacity: 1 !important;
}

.rotate-360 {
  transform: rotate(360deg) !important;
}

.d-inline {
  display: inline;
}
