//------------Break point screen--------------
$small-screen: 576px;
$medium-screen: calc(768px + 1px);
$large-screen: 991px;
$extra-large-screen: 1200px;
$x-extra-large-screen: 1400px;

//----------Paths-------------
$image-path: "/assets/images";
$svg-path: "/assets/svgs";
$font-path: "/assets/fonts";
$icon-path: "/assets/icons";
$fs-icons-path: "/assets/fs-controls-assets/icons";
$fs-svgs-path: "/assets/fs-controls-assets/svgs";

$white-clr: #ffffff;
$white-2-clr: #f2f2f2;
$gray-clr: #bbbcbf;
$gray-2-clr: #575961;
$gray-3-clr: #a5a5a5;
$gray-4-clr: #bdbdbd;
$gray-5-clr: #6a6c74;
$gray-6-clr: #828282;
$gray-7-clr: #a1a4af;
$gray-8-clr: #6a6d77;
$gray-9-clr: #c9cacb;
$gray-10-clr: #e0e0e0;
$gray-11-clr: #3a3c41;
$gray-12-clr: #d8dae3;
$gray-13-clr: #323438;
$gray-14-clr: #bebebe;
$gray-15-clr: #252526;
$gray-16-clr: #686868;
$gray-17-clr: #1a1a1b;
$gray-18-clr: #484848;
$gray-19-clr: #131314;
$gray-20-clr: #b9b9b9;
$gray-21-clr: #6e6e6e;
$gray-22-clr: #353535;
$gray-23-clr: #232427;
$gray-24-clr: #373738;
$gray-25-clr: #adadad;
$gray-26-clr: #5b5b5b;
$green-1-clr: #009840;
$black-1-clr: #000000cc;
$black-2-clr: #000000a1;
$main-bg-clr: #000000;
$primary-clr: #3369ff;
$primary-dark-clr: #214ecc;
$success-clr: #1f883d;
$link-clr: #98a6f9;
$title-clr: #ffc108;
$danger-clr: #e51616;

$dark-gray: #444444;

$button-brd-clr: #858585cc;
$button-bg-clr: #000000b2;
$modal-bg-clr: #000000b2;

$input-bg: #252526;
$input-color-txt: #ffffff;
$form-field-brd-color: #585858;
$input-color-label: #a8a9ac;
$input-hover-color: #838383;
$level3-gray: #29292a;

//New colors
$blue-medium: #3369ff;
$blue-dark: #214ecc;
$blue-light: #3399ff;
$gray-1: #131314;
$gray-2: #252526;
$gray-3: #494a4c;
$gray-4: #bbbcbf;
$gray-5: #ebecf0;
$gray-6: #76777a;
$error-dark: #c73232;
$error-light: #f56e6e;
$success-dark: #1e9428;
$warning-dark: #f2b824;
$overlay-dark: #000000bf;
$club-gradient: linear-gradient(90deg, #3369ff0a 1%, #3369ff80 100%);
//---------------------------------

$grd-gray1-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  linear-gradient(0deg, rgba(133, 133, 133, 0.8), rgba(133, 133, 133, 0.8));

$trn-dur-1: 100ms;
$trn-dur-2: 200ms;
$trn-dur-3: 300ms;
$trn-animate: cubic-bezier(0, 0, 0.2, 1);
$page-anim-bezier: cubic-bezier(0.17, 0.67, 0.35, 0.93);
$navbar-height: 90px;

//Class variables
/*
  Helps: 
    i => Icon
    bg => Background
    ol => Outline
    cr => Circle
*/

//Images
// .splash-banner {
//   background-image: url("#{$image-path}/splash-banner.png");
// }

//Svg
.bm-logo-300 {
  background-image: url("#{$fs-svgs-path}/bm-logo-300.svg");
}

.fs-logo {
  background-image: url("#{$fs-svgs-path}/FS-navbar-logo.svg");
}

//Icons
.fsi-user {
  background-image: url("#{$fs-icons-path}/user-24.svg");
}

.fsi-phone {
  background-image: url("#{$fs-icons-path}/phone-24.svg");
}

.fsi-email {
  background-image: url("#{$fs-icons-path}/email-24.svg");
}

.fsi-close {
  background-image: url("#{$fs-icons-path}/close-24.svg");
}

.fsi-search {
  background-image: url("#{$fs-icons-path}/search-32.svg");
}

.fsi-staff {
  background-image: url("#{$fs-icons-path}/staff-24.svg");
}

.fsi-location {
  background-image: url("#{$fs-icons-path}/location-24.svg");
}

.fsi-superuser {
  background-image: url("#{$fs-icons-path}/superuser-24.svg");
}

.fsi-more {
  background-image: url("#{$fs-icons-path}/more-30.svg");
}

.fsi-calendar {
  background-image: url("#{$fs-icons-path}/calendar-36.svg");
}

.fsi-calendar2 {
  background-image: url("#{$fs-icons-path}/calendar2-36.svg");
}

.fsi-calendar-24 {
  background-image: url("#{$fs-icons-path}/calendar-24.svg");
}

.fsi-clock {
  background-image: url("#{$fs-icons-path}/clock-36.svg");
}

.fsi-clock-reset {
  background-image: url("#{$fs-icons-path}/clock-reset-36.svg");
}

.fsi-checked {
  background-image: url("#{$fs-icons-path}/checked-16.svg");
}

.fsi-tick {
  background-image: url("#{$fs-icons-path}/tick-18.svg");
}

.fsi-arrow-left {
  background-image: url("#{$fs-icons-path}/arrow-left-6.svg");
}

.fsi-arrow-down {
  background-image: url("#{$fs-icons-path}/arrow-down-24.svg");
}

.fsi-arrow-top {
  background-image: url("#{$fs-icons-path}/arrow-top-12.svg");
}

.fsi-remove-25 {
  background-image: url("#{$fs-icons-path}/remove-25.svg");
}

.fsi-vertical-3-dots {
  background-image: url("#{$fs-icons-path}/vertical-3-dots.svg");
}

.fsi-simulator {
  background-image: url("#{$fs-icons-path}/sim-24.svg");
}

.fsi-key {
  background-image: url("#{$fs-icons-path}/key-24.svg");
}

.fsi-integration {
  background-image: url("#{$fs-icons-path}/integration-24.svg");
}

.fsi-link-off {
  background-image: url("#{$fs-icons-path}/link-off-36.svg");
}

.fsi-link-on {
  background-image: url("#{$fs-icons-path}/link-on-36.svg");
}

.fsi-close {
  background-image: url("#{$fs-icons-path}/close-24.svg");
}

.fsi-cir-error {
  background-image: url("#{$fs-icons-path}/circular-error-20.svg");
}

.fsi-cir-success-48 {
  background-image: url("#{$fs-icons-path}/check-circle-48.svg");
}

.fsi-envelope-48 {
  background-image: url("#{$fs-icons-path}/envelope-48.svg");
}

//Background-color
.bg-primary-clr {
  background-color: $primary-clr !important;
  color: $white-clr !important;
}

.bg-success-clr {
  background-color: $green-1-clr !important;
  color: $white-clr !important;
}

.bg-warning-clr {
  background-color: $title-clr !important;
  color: $main-bg-clr !important;
}

.bg-danger-clr {
  background-color: $danger-clr !important;
  color: $white-clr !important;
}

.bg-black-clr {
  background-color: $main-bg-clr !important;
  color: $white-clr !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
  color: $white-clr !important;
}

.bg-lgray-clr {
  background-color: $gray-14-clr !important;
  color: $white-clr !important;
}

//Foreground color
//Background-color
.primary-clr {
  color: $primary-clr !important;
}

.success-clr {
  color: $success-clr !important;
}

.warning-clr {
  color: $title-clr !important;
}

.danger-clr {
  color: $danger-clr !important;
}

.black-clr {
  color: $main-bg-clr !important;
}

.gray-4-clr {
  color: $gray-4-clr !important;
}

//Sizes
$w-btn-size-1: 316px;
$h-btn-size-1: 68px;
.button-size-1 {
  width: $w-btn-size-1;
  height: $h-btn-size-1;
}

$w-btn-size-2: 200px;
$h-btn-size-2: 50px;
.button-size-2 {
  width: $w-btn-size-2;
  height: $h-btn-size-2;
}

$w-btn-size-3: 128px;
$h-btn-size-3: 32px;
.button-size-3 {
  width: $w-btn-size-3;
  height: $h-btn-size-3;
}

$w-btn-size-4: 76px;
$h-btn-size-4: 35px;
.button-size-4 {
  width: $w-btn-size-4;
  height: $h-btn-size-4;
}

$w-btn-size-5: 172px;
$h-btn-size-5: 50px;
.button-size-5 {
  width: $w-btn-size-5;
  height: $h-btn-size-5;
}

$w-btn-size-6: 58px;
$h-btn-size-6: 35px;
.button-size-6 {
  width: $w-btn-size-6;
  height: $h-btn-size-6;
}

$w-btn-size-7: 155px;
$h-btn-size-7: 48px;
.button-size-7 {
  width: $w-btn-size-7;
  height: $h-btn-size-7;
}

$w-btn-size-8: 130px;
$h-btn-size-8: 50px;
.button-size-8 {
  width: $w-btn-size-8;
  height: $h-btn-size-8;
}

$w-btn-size-9: 100px;
$h-btn-size-9: 36px;
.button-size-9 {
  width: $w-btn-size-9;
  height: $h-btn-size-9;
}

$w-btn-size-10: 36px;
$h-btn-size-10: 20px;
.button-size-10 {
  width: $w-btn-size-10;
  height: $h-btn-size-10;
}

$w-size-300: 300px;
$h-size-30: 30px;
.size-300-30 {
  width: $w-size-300;
  height: $h-size-30;
}
