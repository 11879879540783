@media screen and (min-width: 1200px) {
  html {
    font-size: 24px;
  }

  .container {
    width: 30rem;
  }

  $navbar-height: 110px;

  .fs-navbar {
    height: $navbar-height;
  }

  .fs-form-field {
    .fs-field-input,
    .fs-list-selector {
      padding-top: 24px;
      padding-bottom: 6px;
      padding-inline-start: 12px;
      padding-inline-end: 10px;

      label {
        top: 4px;
      }
    }

    .action-field {
      margin: 0 16px;
    }
  }

  .list-selector-header {
    height: 32px;
  }
}

@media screen and (max-width: 1199px) {
  html {
    font-size: 22px;
  }

  .container {
    width: 30rem;
  }

  $navbar-height: 100px;

  .fs-navbar {
    height: $navbar-height;
  }

  .fs-form-field {
    .fs-field-input,
    .fs-list-selector {
      padding-top: 22px;
      padding-bottom: 10px;
      padding-inline-start: 12px;
      padding-inline-end: 10px;

      label {
        top: 4px;
      }
    }

    .action-field {
      margin: 0 16px;
    }
  }

  .list-selector-header {
    height: 26px;
  }
}

@media screen and (max-width: 991px) {
  html {
    font-size: 18px;
  }

  .container {
    width: 30rem;
  }

  $navbar-height: 90px;

  .fs-navbar {
    height: $navbar-height;
  }

  .fs-form-field {
    .fs-field-input,
    .fs-list-selector {
      padding-top: 20px;
      padding-bottom: 10px;
      padding-inline-start: 10px;
      padding-inline-end: 8px;

      label {
        top: 4px;
      }
    }

    .action-field {
      margin: 0 16px;
    }
  }

  .list-selector-header {
    height: 22px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 16px;
  }

  .container {
    width: 100%;
  }

  .fs-form-field {
    .fs-field-input,
    .fs-list-selector {
      padding-top: 18px;
      padding-bottom: 8px;
      padding-inline-start: 10px;
      padding-inline-end: 8px;

      label {
        top: 2px;
      }
    }

    .action-field {
      margin: 0 16px;
    }
  }

  .list-selector-header {
    height: 20px;
  }
}

@media screen and (max-width: 576px) {
  .list-selector-header {
    height: 20px;
  }
}

@media screen and (max-width: 420px) {
}
